import React, { useEffect, useState } from 'react';
import Main from 'features/main/Main';

import { io, Socket } from 'socket.io-client';

function App() {
  const [socket, setSocket] = useState<Socket | undefined>();

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('start socket connection...');
    // const newSocket = io('http://localhost:3001/');
    const newSocket = io('https://satiiria.com:3001');
    setSocket(newSocket);

    return () => {
      newSocket.close();
    };
  }, [setSocket]);

  return <Main socket={socket} />;
}

export default App;
