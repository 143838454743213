import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import classes from './AnimateSelectionModal.module.scss';

interface AnimateSelectionModalProps {
  visible: boolean;
  options: string[];
  selected: number;
  onClose: () => void;
}

const timeouts = [
  700, 650, 600, 550, 500, 400, 350, 300, 250, 200, 150, 120, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100,
  100, 100, 100, 100, 100, 100, 100, 100, 100,
];

function AnimateSelectionModal({ visible, options, selected, onClose }: AnimateSelectionModalProps) {
  const { t } = useTranslation();

  const [current, setCurrent] = useState(0);
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [timeoutIndex, setTimeoutIndex] = useState<number>(0);
  useEffect(() => {
    if (timeoutIndex < timeouts.length) {
      const currentTimeout = timeouts[timeoutIndex];
      const id: NodeJS.Timeout = setTimeout(() => {
        const randomIndex = Math.floor(Math.random() * options.length);
        setCurrent(timeoutIndex >= timeouts.length - 1 ? selected : randomIndex);
        if (timeoutIndex <= timeouts.length) {
          setTimeoutIndex(timeoutIndex + 1);
        } else if (intervalId) {
          clearInterval(intervalId);
        }
      }, currentTimeout);

      setIntervalId(id);
    }

    if (timeoutIndex >= timeouts.length - 1) {
      setCurrent(selected);
    }
    return () => {
      if (intervalId) {
        clearTimeout(intervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options.length, timeoutIndex]);

  return (
    <Modal show={visible} centered>
      <Modal.Header>
        <Modal.Title>{timeoutIndex < timeouts.length ? t('modal.selecting') : t('modal.selected')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Button disabled={timeoutIndex < timeouts.length} className={classes.Current} onClick={onClose}>
          {options[current] || '?'}
        </Button>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={onClose}>{t('modal.close')}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AnimateSelectionModal;
