/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';

import classes from './Input.module.scss';

interface MyInputProps extends React.ComponentPropsWithoutRef<'input'> {
  type: string;
  placeholder: string;
  value: string;
  onChange: any;
  // eslint-disable-next-line react/require-default-props
  selected?: boolean;
}

const input = React.forwardRef<HTMLInputElement, MyInputProps>(
  ({ type, placeholder, value, onChange, selected = false }, ref) => (
    <input
      ref={ref}
      className={[classes.Input, selected ? classes.Selected : ''].join(' ')}
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  ),
);

export default input;
