import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Footer.module.scss';

interface FooterProps {
  onTerms: () => void;
}

function Footer({ onTerms }: FooterProps) {
  const { t } = useTranslation();

  return (
    <footer className={classes.footer}>
      <button className={classes.button} type="button" onClick={onTerms}>
        {t('footer.terms')}
      </button>
      <p className="py-3">{t('footer.copyright')}</p>
    </footer>
  );
}

export default Footer;
