import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './Terms.module.scss';

function Terms() {
  const { t } = useTranslation();
  return (
    <div className={`${classes.Container} px-4 mb-5 py-5 gap-4`}>
      <h1 className="pb-3">{t('terms.title')}</h1>
      <h2>{t('terms.terms.title')}</h2>
      <p>{t('terms.terms.p1')}</p>
      <p>{t('terms.terms.p2')}</p>
      <p>{t('terms.terms.p3')}</p>
      <p>{t('terms.terms.p4')}</p>
      <p>{t('terms.terms.p5')}</p>
      <p>{t('terms.terms.p6')}</p>
      <p>{t('terms.terms.p7')}</p>
      <h2>{t('terms.privacy.title')}</h2>
      <p>{t('terms.privacy.p1')}</p>
      <p>{t('terms.privacy.p2')}</p>
      <p>{t('terms.privacy.p3')}</p>
      <p>{t('terms.privacy.p4')}</p>
      <p>{t('terms.privacy.p5')}</p>
      <h2>{t('terms.cookies.title')}</h2>
      <p>{t('terms.cookies.p1')}</p>
      <p>
        {t('terms.cookies.p2')}
        <a href="https://policies.google.com/privacy/partners?hl=fi" target="_blank" rel="noreferrer">
          {t('terms.cookies.link')}
        </a>
        .
      </p>
      <p>{t('terms.cookies.p3')}</p>
    </div>
  );
}

export default Terms;
