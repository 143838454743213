import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classes from './Guide.module.scss';

function Guide() {
  const { t } = useTranslation();
  return (
    <div className={`${classes.Container} px-4 mb-5 py-5 gap-4`}>
      <h1 className="pb-3">{t('guide.title')}</h1>
      <h2>{t('guide.whatis.title')}</h2>
      <p>{t('guide.whatis.p1')}</p>
      <p>{t('guide.whatis.p2')}</p>
      <h2>{t('guide.howto.title')}</h2>
      <p>
        <Trans i18nKey="guide.howto.p1" />
      </p>
      <p>
        <Trans i18nKey="guide.howto.p2" />
      </p>
      <h2>{t('guide.online.title')}</h2>
      <p>
        <Trans i18nKey="guide.online.p1" />
      </p>
      <p>{t('guide.online.p2')}</p>
      <p>{t('guide.online.p3')}</p>
      <p>
        <Trans i18nKey="guide.online.p4" />
      </p>
      <p>{t('guide.online.p5')}</p>
      <h2>{t('guide.examples.title')}</h2>
      <p>
        <Trans i18nKey="guide.examples.p1" />
      </p>
      <p>
        <Trans i18nKey="guide.examples.p2" />
      </p>
      <p>
        <Trans i18nKey="guide.examples.p3" />
      </p>
      <p>{t('guide.examples.p4')}</p>
      <h2>{t('guide.feedback.title')}</h2>
      <p>{t('guide.feedback.p1')}</p>
    </div>
  );
}

export default Guide;
