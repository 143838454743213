import React, { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import classes from './History.module.scss';

export type HistoryItem = {
  time: Date;
  winner: string;
};

interface HistoryProps {
  history: HistoryItem[];
}

function History({ history }: HistoryProps) {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const onHistory = () => {
    setVisible(!visible);
  };

  const renderItem = (item: HistoryItem, index: number) => {
    return (
      <div className={classes.Item} key={index}>
        {`${moment(item.time).format('H:mm:ss DD.MM.YYYY')}: ${item.winner}`}
      </div>
    );
  };

  if (history.length === 0) return null;

  return (
    <>
      <div className={classes.Wrapper}>
        <button type="button" className={classes.Button} onClick={onHistory}>
          {`${t('history')} (${history.length})`}
          {visible && <FaChevronUp className={classes.Arrow} size={30} />}
          {!visible && <FaChevronDown className={classes.Arrow} size={30} />}
        </button>
      </div>
      {visible && <div className={classes.Container}>{history.map((item, index) => renderItem(item, index))}</div>}
    </>
  );
}

export default History;
