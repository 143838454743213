/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import Input from 'components/Input/Input';
import InputRemovable from 'components/InputRemovable/InputRemovable';
import ButtonAdd from 'components/ButtonAdd/ButtonAdd';
import { Socket } from 'socket.io-client';
import { Button, Container } from 'react-bootstrap';
import Guide from 'components/Guide/Guide';
import Header from 'components/Header/Header';
import History, { HistoryItem } from 'components/History/History';
import Footer from 'components/Footer/Footer';
import Terms from 'components/Terms/Terms';
import AnimateSelectionModal from 'components/AnimateSelectionModal/AnimateSelectionModal';
import classes from './Main.module.scss';

export interface MainProps {
  socket: Socket | undefined;
}

function Main({ socket }: MainProps) {
  const { t } = useTranslation();
  const [question, setQuestion] = useState('');
  const [answers, setAnswers] = useState<Array<string>>(['', '']);
  const [selected, setSelected] = useState(-1);
  const [modalVisible, setModalVisible] = useState(false);
  const [history, setHistory] = useState<Array<HistoryItem>>([]);
  const [roomsize, setRoomsize] = useState(-1);
  const [groupId, setGroupId] = useState('');
  const [termsVisible, setTermsVisible] = useState(false);
  const prevAnswersCountRef = useRef<number>(0);
  const lastAnswerRef = useRef<HTMLInputElement>(null);

  const isInitialOptions = useCallback(() => {
    if (answers.length > 2) {
      return false;
    }
    const firstText = answers.find((answer) => answer.length > 0);
    return !firstText;
  }, [answers]);

  const onJoinGroup = useCallback(
    (newGroupId: string) => {
      if (newGroupId === 'error') {
        // eslint-disable-next-line no-alert
        alert(t('online.error.join'));
        window.location.assign('/');
      } else {
        setGroupId(newGroupId);
        const newurl = `${window.location.href.split('?')[0]}?online=${newGroupId}`;
        window.history.pushState({ path: newurl }, '', newurl);
        if (socket) socket.emit('all', 'I am new here');
      }
    },
    [socket, t],
  );

  const onNewGroup = useCallback(
    (newGroupId: string) => {
      if (newGroupId === 'error') {
        // eslint-disable-next-line no-alert
        alert(t('online.error.create'));
      } else {
        setGroupId(newGroupId);
        const newurl = `${window.location.href.split('?')[0]}?online=${newGroupId}`;
        window.history.pushState({ path: newurl }, '', newurl);
      }
    },
    [t],
  );

  const onCreateNew = useCallback(() => {
    if (socket) socket.emit('join group', '', onNewGroup);
  }, [onNewGroup, socket]);

  useEffect(() => {
    if (prevAnswersCountRef && prevAnswersCountRef.current < answers.length && lastAnswerRef) {
      if (lastAnswerRef.current instanceof HTMLInputElement) {
        lastAnswerRef.current.focus();
        prevAnswersCountRef.current = answers.length;
      }
    }
    if (socket) {
      const params = queryString.parse(window.location.search);
      if (!groupId) {
        if (params.online && typeof params.online === 'string') {
          socket.emit('join group', params.online, onJoinGroup);
        } else {
          onCreateNew();
        }
      }
      const questionListener = (newQuestion: string) => {
        setQuestion(newQuestion);
      };
      const answersListener = (newAnswers: string[]) => {
        setAnswers(newAnswers);
      };
      const selectedListener = (newSelected: number) => {
        setModalVisible(true);
        setSelected(newSelected);
      };
      const historyListener = (newHistory: HistoryItem[]) => {
        setHistory(newHistory);
      };
      const roomsizeListener = (newRoomsize: number) => {
        setRoomsize(newRoomsize);
      };
      const allListener = (joinedId: string) => {
        socket.emit('answers', answers, joinedId);
        socket.emit('selected', selected, joinedId);
        socket.emit('question', question, joinedId);
        socket.emit('history', history, joinedId);
      };

      socket.on('question', questionListener);
      socket.on('answers', answersListener);
      socket.on('selected', selectedListener);
      socket.on('history', historyListener);
      socket.on('roomsize', roomsizeListener);
      socket.on('all', allListener);
      return () => {
        socket.off('question', questionListener);
        socket.off('answers', answersListener);
        socket.off('selected', selectedListener);
        socket.off('history', historyListener);
        socket.off('roomsize', roomsizeListener);
        socket.off('all', allListener);
      };
    }
    return () => {};
  }, [
    setQuestion,
    setAnswers,
    setSelected,
    setHistory,
    setRoomsize,
    onJoinGroup,
    question,
    answers,
    selected,
    history,
    roomsize,
    socket,
    groupId,
    onCreateNew,
  ]);

  const onAdd = useCallback(() => {
    prevAnswersCountRef.current = answers.length;
    const newAnswers = [...answers, ''];
    setAnswers(newAnswers);
    setSelected(-1);
    if (groupId && socket) {
      socket.emit('answers', newAnswers);
      socket.emit('selected', -1);
    }
  }, [answers, groupId, socket]);

  function onRemove(index: number) {
    const newAnswers = [...answers];
    newAnswers.splice(index, 1);
    setAnswers(newAnswers);
    setSelected(-1);
    if (groupId && socket) {
      socket.emit('answers', newAnswers);
      socket.emit('selected', -1);
    }
  }

  function onChangeAnswer(index: number, answer: string) {
    const newAnswers = [...answers];
    newAnswers.splice(index, 1, answer);
    setAnswers(newAnswers);
    setSelected(-1);
    if (groupId && socket) {
      socket.emit('answers', newAnswers);
      socket.emit('selected', -1);
    }
  }

  const onChangeQuestion = useCallback(
    (newQuestion: string) => {
      setQuestion(newQuestion);
      setSelected(-1);
      if (groupId && socket) {
        socket.emit('question', newQuestion);
        socket.emit('selected', -1);
      }
    },
    [groupId, socket],
  );

  const onRandom = useCallback(() => {
    const isInitial = isInitialOptions();
    let answersNow = answers;
    if (isInitial) {
      if (!question) {
        setQuestion(t('headsOrTails.question'));
      }
      const yesno = [t('headsOrTails.heads'), t('headsOrTails.tails')];
      setAnswers(yesno);
      answersNow = yesno;
      if (groupId && socket) socket.emit('answers', yesno);
    }
    const randomIndex = Math.floor(Math.random() * answers.length);
    setModalVisible(true);
    setSelected(randomIndex);
    const newHistory = [...history];
    const winner = answersNow[randomIndex] ? answersNow[randomIndex] : `${t('option.input')} ${randomIndex + 1}`;
    newHistory.push({ time: new Date(), winner });
    setHistory(newHistory);
    if (groupId && socket) {
      socket.emit('selected', randomIndex);
      socket.emit('history', newHistory);
    }
  }, [answers, groupId, history, isInitialOptions, question, socket, t]);

  const onLeaveAndCreateNew = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (window.confirm(`${t('online.restart.confirm1')} ${groupId} ${t('online.restart.confirm2')}`)) {
      if (socket) socket.emit('leave group', groupId);
      setGroupId('');
      setQuestion('');
      setAnswers(['', '']);
      setSelected(-1);
      setHistory([]);
      const newurl = window.location.href.split('?')[0];
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }, [groupId, socket, t]);

  const onToggleShowterms = useCallback(() => {
    setTermsVisible(!termsVisible);
  }, [termsVisible]);

  const onReset = useCallback(() => {
    // eslint-disable-next-line no-alert
    if (window.confirm(t('reset.confirm'))) {
      setQuestion('');
      setAnswers(['', '']);
      setSelected(-1);
      if (groupId && socket) {
        socket.emit('answers', []);
        socket.emit('selected', -1);
        socket.emit('question', '');
      }
    }
  }, [groupId, socket, t]);

  function renderAnswer(answer: string, index: number) {
    const currentIsSelected = !modalVisible && selected === index;
    return (
      <InputRemovable
        ref={lastAnswerRef}
        key={index}
        value={answer}
        onChange={(newAnswer: string) => onChangeAnswer(index, newAnswer)}
        onRemove={() => onRemove(index)}
        placeholder={`${t('option.input')} ${index + 1}`}
        selected={currentIsSelected}
      />
    );
  }

  function renderAnswers() {
    return answers.map((answer, index) => renderAnswer(answer, index));
  }

  function renderOnline() {
    return <div className={classes.OnlineContainer}>{`ONLINE (${roomsize}) ID: ${groupId}`}</div>;
  }

  return (
    <>
      <Container className="px-0">
        <Header />
        {groupId && (
          <>
            {renderOnline()}
            <Container className={`${classes.Container} gap-2 mt-3 mb-5`}>
              <Button
                className={classes.onlineButton}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                }}
              >
                {t('online.copy')}
              </Button>
              <Input type="text" placeholder={t('question.placeholder')} value={question} onChange={onChangeQuestion} />
              {renderAnswers()}
              <ButtonAdd onAdd={onAdd}>{t('option.add')}</ButtonAdd>
              <Button onClick={onRandom}>{t('random')}</Button>
              {(question.length > 0 || !isInitialOptions()) && (
                <Button variant="outline-primary" onClick={onReset}>
                  {t('reset.button')}
                </Button>
              )}
              <History history={history} />
              <Button className={classes.onlineButton} onClick={onLeaveAndCreateNew}>
                {t('online.restart.button')}
              </Button>
            </Container>
          </>
        )}
        <Guide />
        {termsVisible && <Terms />}
      </Container>
      <Footer onTerms={onToggleShowterms} />
      {modalVisible && selected !== -1 && (
        <AnimateSelectionModal visible options={answers} selected={selected} onClose={() => setModalVisible(false)} />
      )}
    </>
  );
}

export default Main;
