import React, { useState } from 'react';
import cover from 'assets/images/cover.png';
import ChooseFlag from 'components/ChooseFlag/ChooseFlag';
import { useTranslation } from 'react-i18next';
import classes from './Header.module.scss';

function Header() {
  const { i18n } = useTranslation();
  const [flag, setFlag] = useState(i18n.language.includes('en') ? 'gb' : 'fi');
  const [option, setOption] = useState(i18n.language.includes('en') ? 'fi' : 'gb');
  const maxHeight = Math.floor(window.innerHeight / 3);

  const onChangeLanguage = (selected: string) => {
    if (selected === 'fi') {
      i18n.changeLanguage('fi');
      setFlag('fi');
      setOption('gb');
    } else {
      i18n.changeLanguage('en');
      setFlag('gb');
      setOption('fi');
    }
  };

  return (
    <header className={classes.Container}>
      <img className={classes.cover} style={{ maxHeight }} src={cover} alt="cover" />
      <ChooseFlag selected={flag} options={[option]} onSelected={onChangeLanguage} />
    </header>
  );
}

export default Header;
