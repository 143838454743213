/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/button-has-type */
import React, { FunctionComponent } from 'react';
import { MdAdd } from 'react-icons/md';

import classes from './ButtonAdd.module.scss';

type ButtonAddProps = {
  onAdd: any;
  children: React.ReactNode;
};

const button: FunctionComponent<ButtonAddProps> = ({ onAdd, children }) => {
  return (
    <div className={classes.Wrapper}>
      <button className={classes.Button} onClick={onAdd}>
        <MdAdd className={classes.Add} size={30} />
        {children}
      </button>
    </div>
  );
};

export default button;
