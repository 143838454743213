/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/button-has-type */
import React from 'react';
import { MdCancel } from 'react-icons/md';
import Input from '../Input/Input';

import classes from './InputRemovable.module.scss';

interface InputRemovableProps extends React.ComponentPropsWithoutRef<'input'> {
  value: string;
  onChange: any;
  onRemove: any;
  placeholder: string;
  // eslint-disable-next-line react/require-default-props
  selected?: boolean;
}

const InputRemovable = React.forwardRef<HTMLInputElement, InputRemovableProps>(
  ({ value, onChange, onRemove, placeholder, selected = false }, ref) => (
    <div className={classes.Wrapper}>
      <Input ref={ref} selected={selected} type="text" placeholder={placeholder} value={value} onChange={onChange} />
      <button className={classes.Button} onClick={onRemove}>
        <MdCancel className={classes.icon} size={40} />
      </button>
    </div>
  ),
);

export default InputRemovable;
